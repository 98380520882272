<template>
  <div class="consume">
    <el-row class="consume-container">
      <el-col :span="6" class="consume-container-money">
        <p class="consume-container-title">昨日消费
          <el-tooltip placement="top">
            <i class="el-icon-question"/>
            <span class="consume-container-remark" slot="content">消费金额包含设备计费与模块使用计费</span>
          </el-tooltip>

        </p>
        <p class="consume-container-price import">¥ <span>{{(consume.yesterdayMoney / 100) | F1000}}
          <span style="font-size: 16px;margin-left: 0">元</span>
        </span>
        </p>

      </el-col>

      <el-col :span="6" class="consume-container-money">
        <p class="consume-container-title">本月消费</p>
        <p class="consume-container-price">¥ <span>{{(consume.monthMoney / 100) | F1000}}</span>
          <span style="font-size: 16px;margin-left: 8px;">元</span>
        </p>
      </el-col>

      <el-col :span="24">
        <p class="consume-container-remark" style="margin-top: 16px;padding-left: 16px;">
          <el-icon type="question-circle"/> 相关消费计算每日晚上 23点更新一次，计费周期为未结算月份 1 号 0:00 至当日 0:00 。
          为防止设备停机，请保障账户存有适量余额。</p>
      </el-col>

    </el-row>

    <el-table
      class="consume-list"
      :columns="columns"
      :row-key="record => record.uuid"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
    </el-table>
  </div>
</template>

<script>

  export default {
    name: 'org-basic-consume',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        consume: {
          yesterdayMoney: 9999,
          monthMoney: 9999,
          selfDefMoney: 9999,
        },
        data: [],
        pagination: {
          current: 1,
          total: 5,
        },
        loading: false,
        columns: [
          {
            title: '账期',
            dataIndex: 'createdAt',
            scopedSlots: {customRender: 'name'},
          },
          {
            title: '收支类型',
            dataIndex: 'invoiceType',
            filters: [
              {text: '设备计费', value: 'male'},
              {text: '模块计费', value: 'female'},
            ],
          },
          {
            title: '交易类型',
            dataIndex: 'invoiceTitle',
          },
          {
            title: '交易渠道',
            dataIndex: 'invoiceTitle',
          },
          {
            title: '资金形式',
            dataIndex: 'invoiceTitle',
            width: '15%',
          },
          {
            title: '备注说明',
            dataIndex: 'invoiceMoney',
          },
          {
            title: '金额',
            dataIndex: 'invoiceMoney',
          },
          {
            title: '账户余额',
            dataIndex: 'invoiceMoney',
          },
          {
            title: '创建时间',
            dataIndex: 'invoiceMoney',
          },
        ]
      }
    },
    methods: {
      disabledDate() {
        // Can not select days before today and today
        return true;
      },

      disabledDateTime() {
        return {
          disabledHours: () => this.range(0, 24).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      },

      disabledRangeTime(_, type) {
        if (type === 'start') {
          return {
            disabledHours: () => this.range(0, 60).splice(4, 20),
            disabledMinutes: () => this.range(30, 60),
            disabledSeconds: () => [55, 56],
          };
        }
        return {
          disabledHours: () => this.range(0, 60).splice(20, 4),
          disabledMinutes: () => this.range(0, 31),
          disabledSeconds: () => [55, 56],
        };
      },
      handleTableChange() {
      },
    },
  }
</script>

<style lang="scss" scoped>
  .consume {
    padding: $container-padding;

    &-container {
      height: 100px;

      &-title {
        @include font-medium();
        font-weight: 500;

        i {
          cursor: pointer;
        }

        i:hover {
          color: $theme-color;
        }
      }


      &-price {
        @include  font-large-s();
        margin: $small-space 0;

        span {
          display: inline-block;
          margin-left: $small-space;
        }
      }

      .import {
        color: $theme-color;
        font-weight: 500;
      }

      &-remark {
        @include font-little();
        margin-bottom: $middle-space;
        color: $remark-text-color;
      }


      &-money {
        padding-left: $middle-space;
      }
    }

    &-list {
      margin: $middle-space 0;

    }


  }
</style>
